<template>
    <div class="ms_rcnt_slider" style="padding-top: 5%;">
        <div class="ms_heading">
            <h1>الفنانين</h1>
        </div>
        <div class="swiper-container">
            <div class="swiper-wrapper">
                <vueper-slides 
                    autoplay
                    rtl
                    class="no-shadow swiper-slide "
                    :visible-slides="4"
                    slide-multiple
                    :gap="3"
                    :slide-ratio="1 / 5"
                    :dragging-distance="200"
                    :breakpoints="{ 800: { visibleSlides: 1, slideMultiple: 1 } }">
                    <vueper-slide class="ms_rcnt_box" v-for="(art, index) in carousol.slice(0,8)" :key="index" :image="art.img" :title="art.title_ar" @click="getCarousol(art)" />
                    <vueper-slide class="ms_rcnt_box" v-for="(art, index) in carousol.slice(8,12)" :key="index" :image="art.img" :title="art.title_ar" @click="getCarousol(art)" />
                </vueper-slides>
            </div>
        </div>
    </div>
 <!----Top Artist Section---->
            <div class="ms_top_artist">
                <div class="container-fluid">
                    <div class="row row--grid">
                        <div class="col-12">
                            <div class="ms_heading">
                                <h1>التصنيفات</h1>
                            </div>
                        </div>
                        <div class="col-6 col-sm-4 col-lg-3 col-xl-2" v-for="cat in categorie" :key="cat.id">
                            <div class="ms_rcnt_box marger_bottom30 pointer" @click="getCategorie(cat)">
                                <div class="ms_rcnt_box_img margin-zero" style="max-height: 10vh;">
                                    <img v-lazy="cat.img" alt="" class="img-fluid">
                                    <div class="ms_main_overlay">
                                        <div class="ms_box_overlay"></div>
                                    </div>
                                </div>
                                <div class="ms_rcnt_box_text card_top_margin" style="z-index:999;display:block;position:relative;">
                                    <h3><a class="text_size" style="color: rgb(255, 255, 255); font-weight: 600;font-size: 15px;" @click="getCategorie(cat)">{{ cat.name }}</a></h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!----Top Artist Section---->
            <div class="ms_top_artist">
            <div class="row" >
                    <div class="col-lg-12">
                        <div class="ms_heading" >
                            <h1 >الربابة</h1>
                        </div>
                    </div>
                    <div class="col-12">
                        <Carousel :settings="settings" :breakpoints="breakpoints" :dir="rtl" :autoplay="2300">
                                <Slide v-for="(slide, index) in Rababh" :key="index">
                                    <div class="ms_rcnt_box marger_bottom30 carousel__item">
                                    <div class="ms_rcnt_box_img" @click="getFeed(slide)">
                                        <img v-lazy="slide.img" alt="" class="img-fluid">
                                        <div class="ms_main_overlay">
                                            <div class="ms_box_overlay"></div>
                                            <div class="ms_play_icon" @click="getFeed(slide)">
                                                <img src="../../assets/images/svg/play.svg" alt="">
                                            </div>
                                        </div>
                                        <div class="ms_box_overlay_on">
                                            <div class="ovrly_text_div">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="ms_rcnt_box_text pointer">
                                        <h3><a @click="getFeed(slide)">{{ slide.cat_name }}</a></h3>
                                        <h3><a @click="getFeed(slide)">{{ slide.name }}</a></h3>
                                    </div>
                                </div>
                                </Slide>
                        </Carousel>
                    </div>
                    <div class="col-lg-12">
                        <div class="ms_heading" >
                            <h1 >الطمبور</h1>
                        </div>
                    </div>
                    <div class="col-12">
                        <Carousel :settings="settings" :breakpoints="breakpoints" :dir="rtl" :autoplay="2500">
                                <Slide v-for="(slide, index) in Tamboor" :key="index">
                                   <div class="ms_rcnt_box marger_bottom30 carousel__item">
                                    <div class="ms_rcnt_box_img" @click="getFeed(slide)">
                                        <img v-lazy="slide.img" alt="" class="img-fluid">
                                        <div class="ms_main_overlay">
                                            <div class="ms_box_overlay"></div>
                                            <div class="ms_play_icon" @click="getFeed(slide)">
                                                <img src="../../assets/images/svg/play.svg" alt="">
                                            </div>
                                        </div>
                                        <div class="ms_box_overlay_on">
                                            <div class="ovrly_text_div">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="ms_rcnt_box_text pointer">
                                        <h3><a @click="getFeed(slide)">{{ slide.cat_name }}</a></h3>
                                        <h3><a @click="getFeed(slide)">{{ slide.name }}</a></h3>
                                    </div>
                                </div>
                                </Slide>
                        </Carousel>
                    </div>
                    <div class="col-lg-12">
                        <div class="ms_heading" >
                            <h1 >السيرة والدلوكة</h1>
                        </div>
                    </div>
                    <div class="col-12" style="margin-bottom: 15vh;">
                        <Carousel :settings="settings" :breakpoints="breakpoints" :dir="rtl" :autoplay="2700">
                                <Slide v-for="(slide, index) in Serah" :key="index">
                                    <div class="ms_rcnt_box marger_bottom30 carousel__item">
                                    <div class="ms_rcnt_box_img" @click="getFeed(slide)">
                                        <img v-lazy="slide.img" alt="" class="img-fluid">
                                        <div class="ms_main_overlay">
                                            <div class="ms_box_overlay"></div>
                                            <div class="ms_play_icon" @click="getFeed(slide)">
                                                <img src="../../assets/images/svg/play.svg" alt="">
                                            </div>
                                        </div>
                                        <div class="ms_box_overlay_on">
                                            <div class="ovrly_text_div">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="ms_rcnt_box_text pointer">
                                        <h3><a @click="getFeed(slide)">{{ slide.cat_name }}</a></h3>
                                        <h3><a @click="getFeed(slide)">{{ slide.name }}</a></h3>
                                    </div>
                                </div>
                                </Slide>
                        </Carousel>
                    </div>
                    <!-- <div class="col-lg-12">
                        <div class="ms_heading" >
                            <h1 >منوعات</h1>
                        </div>
                    </div>
                    <div class="col-12" style="margin-bottom: 15vh;">
                        <Carousel :settings="settings" :breakpoints="breakpoints" :dir="rtl" :autoplay="2300">
                                <Slide v-for="(slide, index) in Monaw3" :key="index">
                                    <div class="ms_rcnt_box marger_bottom30 carousel__item">
                                    <div class="ms_rcnt_box_img" @click="getFeed(slide)">
                                        <img v-lazy="slide.img" alt="" class="img-fluid">
                                        <div class="ms_main_overlay">
                                            <div class="ms_box_overlay"></div>
                                            <div class="ms_play_icon" @click="getFeed(slide)">
                                                <img src="../../assets/images/svg/play.svg" alt="">
                                            </div>
                                        </div>
                                        <div class="ms_box_overlay_on">
                                            <div class="ovrly_text_div">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="ms_rcnt_box_text pointer">
                                        <h3><a @click="getFeed(slide)">{{ slide.cat_name }}</a></h3>
                                        <h3><a @click="getFeed(slide)">{{ slide.name }}</a></h3>
                                    </div>
                                </div>
                                </Slide>
                        </Carousel>
                    </div> -->
                    
            </div>
        </div>
</template>

<script>
import { HTTP } from '@/Api/http-common';
import { HTTPDSP } from '@/Api/http-dsp';
import { ref } from '@vue/reactivity';
import { useRouter, useRoute } from "vue-router"
import { useCookie } from 'vue-cookie-next';
import { VueperSlides, VueperSlide } from 'vueperslides'
import 'vueperslides/dist/vueperslides.css'
import { useToast } from "vue-toastification";
import { Carousel, Slide } from 'vue3-carousel'
import 'vue3-carousel/dist/carousel.css'
import { watchEffect } from '@vue/runtime-core';
export default {
  name: 'AppHome',
    data() {
        return {
           myObject: null,
           // carousel settings
    settings: {
      itemsToShow: 2,
      snapAlign: 'center',
    },
    // breakpoints are mobile first
    // any settings not specified will fallback to the carousel settings
    breakpoints: {
      // 700px and up
      414: {
        itemsToShow: 2,
        snapAlign: 'center',
      },
      700: {
        itemsToShow: 3.5,
        snapAlign: 'center',
      },
      // 1024 and up
      1024: {
        itemsToShow: 5,
        snapAlign: 'start',
      },
    },
        }
    },
  components: { VueperSlides, VueperSlide , Carousel, Slide},
   
  async setup() {
      const router = useRouter();
      const route = useRoute();
      const cookie = useCookie();
      const toast = useToast();
      const carousol = ref([]);
      const categorie = ref([]);
      const artist = ref([]); 
      const artistsData = ref([]); 
      const Tamboor = ref([]); 
      const Rababh = ref([]); 
      const Serah = ref([]); 
      const Monaw3 = ref([]); 


      watchEffect(() => {
        if (!cookie.isCookieAvailable("msisdn") && !cookie.isCookieAvailable("status")) {
          let urlParams = new URLSearchParams(window.location.search);
          if(urlParams.has('msisdn')) {
            HTTPDSP.post(`DSPCheckLogin.php?msisdn=`+urlParams.get('msisdn')).then((res) => {
                if (res.data.status == 1 ) {
                    cookie.setCookie('msisdn', urlParams.get('msisdn'), { expire: 60 * res.data.remming_minutes, })
                    cookie.setCookie('status', res.data.status, { expire: 60 * res.data.remming_minutes, })
                    cookie.setCookie('remming_minutes', res.data.remming_minutes, { expire: 60 * res.data.remming_minutes, })
                    toast.success("تم تسجيل دخولك", { timeout: 1000 });
                    // if(cookie.getCookie("content_id")!=null){
                    //   toast.info("سوف يتم تحويلك الي المحتوي", { timeout: 2000 });
                    //   setTimeout(() =>  router.push({name: "Contents", params: { id: cookie.getCookie("content_id") } }), 2000);
                    // } else {
                      setTimeout(() => router.push({ path: "/" }), 2500);
                    // }
                } else if (res.data.status == 0 ) {
                  cookie.removeCookie('msisdn');
                    toast.error("لست مشترك في هذة الخدمة", { timeout: 1000 });
                    setTimeout(() => router.push({ path: "/" }), 2500);
                }
                
                })
            }
        }
      });

      try {
         HTTP.get(`GetCarousol.php`).then((res) => {
          carousol.value = res.data.Content; 
        });
      } catch (err) {
        console.log(err);
      }

      try {
         HTTP.get(`GetArtistsByCategory.php?ID=5`).then((res) => {
          
          Tamboor.value = res.data.Artists; 
        });
      } catch (err) {
        console.log(err);
      }
      try {
         HTTP.get(`GetArtistsByCategory.php?ID=4`).then((res) => {
          
          Rababh.value = res.data.Artists; 
        });
      } catch (err) {
        console.log(err);
      }
      try {
         HTTP.get(`GetArtistsByCategory.php?ID=6`).then((res) => {
          
          Serah.value = res.data.Artists; 
        });
      } catch (err) {
        console.log(err);
      }
      try {
         HTTP.get(`GetArtistsByCategory.php?ID=7`).then((res) => {
          
          Monaw3.value = res.data.Artists; 
        });
      } catch (err) {
        console.log(err);
      }

      try {
        await HTTP.get('GetAllCategories.php').then((res) => {
            categorie.value = res.data.Categories; 
        });
      } catch (err) {
        console.log(err);
      }

       try {
        await HTTP.get(`GetAllArtists.php`).then((res) => {
            artist.value = res.data.Artists; 
        });
      } catch (err) {
        console.log(err);
      }
      const getCarousol = (art) => {
          router.push({ name: "Contents", params: { content_id: art.sub_cat_id, cat_name: art.cat_name } });
      };

      const getCategorie = (cat) => {
          router.push({ name: "Categories", params: { cat_id: cat.id, cat_name: cat.name } });
      };
      const getFeed = (slide) => {
          router.push({ name: "Contents", params: { content_id: slide.id, cat_name: slide.cat_name } });
      };
      const getArtist = (artist_id)=>{
        try {
         HTTP.get(`GetArtistsByCategory.php?ID=`+artist_id).then((res) => {
          
          artistsData.value = res.data.Artists; 
        });
      } catch (err) {
        console.log(err);
      }
        return artistsData;
      }
      return { carousol,categorie,artist,Tamboor,Rababh,Serah,Monaw3,getCategorie,getArtist, getFeed,getCarousol };
   },
}
</script>

<style>

/* .ms_box_overlay_on {
      position: absolute;
  left: 0;
  content: "";
  width: 100%;
  height: 100%;
  top: 0;
  border-radius: 10px;
  background-image: -moz-linear-gradient( 90deg, rgb(20, 24, 42) 0%, rgb(237, 63, 179) 0%, rgb(52, 62, 105) 0%, rgba(32, 167, 196, 0) 100%);
  background-image: -webkit-linear-gradient( 90deg, rgb(20, 24, 42) 0%, rgb(237, 63, 179) 0%, rgb(52, 62, 105) 0%, rgba(32, 167, 196, 0) 100%);
  background-image: -ms-linear-gradient( 90deg, rgb(20, 24, 42) 0%, rgb(237, 63, 179) 0%, rgb(52, 62, 105) 0%, rgba(32, 167, 196, 0) 100%);
  transition: all 0.4s ease-in-out;
  -webkit-transition: all 0.4s ease-in-out;
  -moz-transition: all 0.4s ease-in-out;
} */

.vueperslide {
 white-space:normal;
 background-size:cover;
 flex-shrink:0;
 display:block;
 width:100%;
 position:relative
}
.vueperslide--clone-1 {
 position:absolute;
 top:0;
 bottom:0;
 right:100%
}
.vueperslides--rtl .vueperslide--clone-1 {
 right:auto;
 left:100%
}
.vueperslide[href] {
 -webkit-user-drag:none
}
.vueperslide__image {
 position:absolute;
 top:0;
 left:0;
 right:0;
 bottom:0;
 background-size:cover
}
.vueperslide__loader {
 position:absolute;
 top:0;
 bottom:0;
 left:0;
 right:0;
 display:flex;
 flex-direction:column;
 align-items:center;
 justify-content:center
}
.vueperslide__content-wrapper:not(.vueperslide__content-wrapper--outside-top):not(.vueperslide__content-wrapper--outside-bottom) {
 height:100%;
 margin:auto
}
.vueperslides--fade .vueperslide {
 position:absolute;
 top:0;
 left:0;
 right:0;
 bottom:0;
 opacity:0;
 transition:ease-in-out opacity;
 transition-duration:inherit
}
.vueperslides--fade .vueperslide--active,
.vueperslides--fade .vueperslide--visible {
 z-index:1;
 opacity:1
}
.vueperslides--slide-image-inside .vueperslide {
 overflow:hidden
}
.vueperslides--3d .vueperslide {
 position:absolute;
 z-index:-1;
 height:100%
}
.vueperslides--3d .vueperslide--previous-slide,
.vueperslides--3d .vueperslide--active,
.vueperslides--3d .vueperslide--next-slide {
 z-index:0
}
.vueperslides--3d .vueperslide--active {
 z-index:1
}
.vueperslides--3d .vueperslide[face=front] {
 transform:rotateY(90deg) translate(-50%) rotateY(-90deg)
}
.vueperslides--3d .vueperslide[face=right] {
 transform:rotateY(90deg) translate(50%);
 transform-origin:100% 0
}
.vueperslides--3d .vueperslide[face=back] {
 transform:rotateY(270deg) translate(-50%) rotateY(-90deg)
}
.vueperslides--3d .vueperslide[face=left] {
 transform:rotateY(270deg) translate(-50%);
 transform-origin:0 0
}
.vueperslides:not(.no-shadow):not(.vueperslides--3d) .vueperslides__parallax-wrapper:before,
.vueperslides:not(.no-shadow):not(.vueperslides--3d) .vueperslides__parallax-wrapper:after {
 content:"";
 position:absolute;
 bottom:100%;
 left:-1em;
 right:-1em;
 height:2em;
 box-shadow:0 0 20px #00000040;
 z-index:2
}
.vueperslides:not(.no-shadow):not(.vueperslides--3d) .vueperslides__parallax-wrapper:after {
 top:100%;
 bottom:auto
}
.vueperslides__arrows {
 color:#fff
}
.vueperslides__arrows--outside {
 color:currentColor
}
.vueperslides__arrow {
 top:50%;
 background-color:transparent;
 border:none;
 opacity:.7
}
.vueperslides__arrow--prev,
.vueperslides--rtl .vueperslides__arrow--next {
 right:auto;
 left:.5em
}
.vueperslides__arrow--next,
.vueperslides--rtl .vueperslides__arrow--prev {
 left:auto;
 right:.5em
}
.vueperslides__arrow:hover {
 opacity:1
}
.vueperslides__arrows--outside .vueperslides__arrow--prev,
.vueperslides--rtl .vueperslides__arrows--outside .vueperslides__arrow--next {
 right:auto;
 left:-3.5em
}
.vueperslides__arrows--outside .vueperslides__arrow--next,
.vueperslides--rtl .vueperslides__arrows--outside .vueperslides__arrow--prev {
 left:auto;
 right:-3.5em
}
.vueperslides__paused {
 top:.7em;
 right:.7em;
 opacity:0;
 text-shadow:0 0 3px rgba(0,0,0,.4);
 z-index:1
}
.vueperslides:hover .vueperslides__paused {
 opacity:1
}
.vueperslides__bullets:not(.vueperslides__bullets--outside) {
 color:#70499D
}
.vueperslides__bullet {
 margin:1.5em .6em;
 padding:0;
 border:none;
 background:none
}
.vueperslides__bullet .default {
 width:12px;
 height:12px;
 border-radius:12px;
 border:1px solid currentColor;
 background-color:transparent;
 box-shadow:0 0 1px #00000080,0 0 3px #0000004d;
 transition:.4s ease-in-out;
 box-sizing:border-box
}
.vueperslides__bullet .default span {
 display:none
}
.vueperslides__bullet--active .default {
 border-width:6px
}
.vueperslide,
.vueperslide__image {
 background-position:center
}
.vueperslide__video {
 outline:none
}
.vueperslide--no-pointer-events:before {
 content:"";
 position:absolute;
 top:0;
 bottom:0;
 left:0;
 right:0
}
.vueperslide__content-wrapper:not(.vueperslide__content-wrapper--outside-top):not(.vueperslide__content-wrapper--outside-bottom) {
 display: flex;
flex: 1 1 auto;
flex-direction: column;
align-items: center;
justify-content: center;
text-align: center;
background: #00000096;;
height: 25%;
bottom: 0%;
position: absolute;
width: 100%;
/* border: 2px solid #5375AC; */
font-size: 16px;
color: #fff;
z-index: 999;
border-radius: 10px;
flex-direction: column;
}
.vueperslide__content-wrapper.parallax-fixed-content,
.vueperslide--has-image-inside .vueperslide__content-wrapper,
.vueperslide--has-video .vueperslide__content-wrapper {
 position:absolute;
 z-index:2;
 top:0;
 bottom:0;
 left:0;
 right:0;
 display:flex;
 flex-direction:column;
 justify-content:center;
 align-items:center;
 pointer-events:none
}
.vueperslides {
 position:relative;
 min-height: 40vh;
}
.vueperslides--fixed-height .vueperslides__inner,
.vueperslides--fixed-height .vueperslides__parallax-wrapper,
.vueperslides--fixed-height .vueperslide {
 height:inherit
}
.vueperslides--fixed-height .vueperslides__parallax-wrapper {
 padding-bottom:0!important
}
.vueperslides--fixed-height.vueperslides--bullets-outside {
 margin-bottom:4em
}
.vueperslides__inner {
 position:relative;
 -webkit-user-select:none;
 -moz-user-select:none;
 user-select:none;
 min-height: 25vh;
}
.vueperslides__parallax-wrapper {
 position:relative;
 overflow:hidden;
 min-height: 35vh;
}
.vueperslides--3d .vueperslides__parallax-wrapper {
 overflow:visible
}
.vueperslides__track {
 position:absolute;
 top:0;
 height:100%;
 left:0;
 right:0;
 overflow:hidden;
 z-index:1;
 border-radius: 12px
}
.vueperslides--parallax .vueperslides__track {
 height:200%;
 transform:translateY(0)
}
.vueperslides--touchable .vueperslides__track {
 cursor:ew-resize;
 cursor:-webkit-grab;
 cursor:grab
}
.vueperslides--touchable .vueperslides__track--mousedown,
.vueperslides--touchable .vueperslides__track--dragging {
 cursor:-webkit-grabbing;
 cursor:grabbing
}
.vueperslides--3d .vueperslides__track {
 overflow:visible;
 perspective:100em
}
.vueperslides__track-inner {
 white-space:nowrap;
 transition:.5s ease-in-out transform;
 height:100%;
 display:flex
}
.vueperslides--no-animation .vueperslides__track-inner {
 transition-duration:0s!important
}
.vueperslides--fade .vueperslides__track-inner {
 white-space:normal;
 transition:none
}
.vueperslides--3d .vueperslides__track-inner {
 transform-style:preserve-3d
}
.vueperslides__track--mousedown .vueperslides__track-inner {
 transition:.25s ease-in-out transform!important
}
.vueperslides__track--dragging .vueperslides__track-inner {
 transition:none
}
.vueperslides__arrow {
 position:absolute;
 font-size:inherit;
 color:inherit;
 text-align:center;
 transform:translateY(-50%);
 transition:.3s ease-in-out;
 cursor:pointer;
 -webkit-user-select:none;
 -moz-user-select:none;
 user-select:none;
 outline:none;
 z-index:2;
 line-height:1
}
.vueperslides__arrow svg {
 vertical-align:middle;
 stroke:currentColor;
 fill:none;
 width:3.5em;
 padding:1em;
 stroke-width:1;
 transition:.3s ease-in-out;
 box-sizing:border-box;
  display: none
}
.vueperslides__arrow svg:hover {
 stroke-width:1.3
}
.vueperslides__paused {
 position:absolute;
 transition:.3s ease-in-out
}
.vueperslides__bullets {
 display:flex;
 justify-content:center;
 position:absolute;
 bottom:0;
 left:0;
 right:0
}
.vueperslides__bullets--outside {
 position:relative
}
.vueperslides__bullets button,
.vueperslides__bullet {
 cursor:pointer;
 -webkit-user-select:none;
 -moz-user-select:none;
 user-select:none;
 outline:none;
 z-index:2;
 display:flex;
 justify-content:center;
 align-items:center;
 color:inherit
}
.vueperslides__bullets button::-moz-focus-inner,
.vueperslides__bullet::-moz-focus-inner {
 border:0
}
.vueperslides__fractions {
 position:absolute;
 top:.8em;
 left:.5em;
 z-index:2;
 padding:.2em 1em;
 border:1px solid rgba(255,255,255,.5);
 border-radius:2em;
 background:rgba(255,255,255,.2);
 color:#fff
}
.vueperslides__progress {
 position:absolute;
 top:0;
 left:0;
 right:0;
 z-index:2;
 height:6px;
 color:#000000b3
}
.vueperslides__progress>* {
 position:absolute;
 top:0;
 bottom:0;
 left:0;
 background:currentColor;
 transition:.3s ease-in-out
}
@media(max-width:768px){
.vueperslides__bullets {
        top: 100%;
}
}

.vueperslides__bullets button,
.vueperslides__bullet {
 cursor:pointer;
 -webkit-user-select:none;
 -moz-user-select:none;
 -ms-user-select:none;
     user-select:none;
 outline:none;
 z-index:2;
 display:flex;
 justify-content:center;
 align-items:center;
 color:#70499D;
}

.vueperslide {
  white-space: normal;
  background-size: cover;
  flex-shrink: 0;
  display: block;
  width: 100%;
  position: relative;
  /* height: 400px; */
  height: 403px;
}


.vueperslide__content-wrapper:not(.vueperslide__content-wrapper--outside-top):not(.vueperslide__content-wrapper--outside-bottom) {
  text-align: center;
  font-size: 16px;
  color: #fff;
  height: 36%;
  padding-bottom: 25%;
}


@media (max-width: 760px){
  .vueperslide__content-wrapper:not(.vueperslide__content-wrapper--outside-top):not(.vueperslide__content-wrapper--outside-bottom) {
    height: 36%;
    bottom: 10%;
  }
}

@media (max-width: 412px){
  .vueperslide__content-wrapper:not(.vueperslide__content-wrapper--outside-top):not(.vueperslide__content-wrapper--outside-bottom) {
    height: 36%;
    bottom: 7%;
  }
}

</style>